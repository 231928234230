<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="История сообщений"
    hide-footer
    header-class="monitoring-create-call-header"
    @hidden="onClose"
    @ok="onClose"
    @close="onClose"
  >
    <template>
      <div class="mb-[24px]">
        <div v-if="!totalCount">
          Ничего не найдено
        </div>
        <div v-else>
          <div
            v-if="isLoading"
            class="d-flex justify-content-center mt-5"
          >
            <b-spinner
              label="Loading..."
              variant="primary"
              style="width: 5rem; height: 5rem;"
            />
          </div>
          <sms-info
            v-for="(message, index) in messages"
            :key="index"
            :message="message"
            :show-sms-new="false"
            class="message-info mt-3"
          />
        </div>
        <div
          v-if="totalCount"
          class="d-flex align-items-center mt-3"
        >
          <b-dropdown
            id="dropdown-1"
            :text="`${limit}`"
            split
            class="mr-2 bg-white"
            size="sm"
            split-variant="outline-primary"
            variant="primary"
          >
            <b-dropdown-item @click="tableSizeHandler(10)">
              10
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="tableSizeHandler(25)">
              25
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="tableSizeHandler(50)">
              50
            </b-dropdown-item>
          </b-dropdown>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="limit"
            class="m-0"
            @input="() => messagesFetch()"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { format } from '@evd3v/date-fns';
import SmsInfo from '@/components/Sms/SmsInfo';
import { FINAL_STATUS_TYPE } from '@/helpers/consts';
import { remoteMonitoringService } from '@/services';

export default {
  name: 'MonitoringMessagesHistoryModal',
  components: { SmsInfo },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isCall: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      date: new Date().toISOString(),
      isSaving: false,
      text: '',
      status: '',
      messages: [],
      limit: 25,
      currentPage: 1,
      totalCount: 0,
      isLoading: false,
      isOpen: true,
    };
  },
  computed: {
    finalStatuses() {
      return FINAL_STATUS_TYPE;
    },
  },
  async created() {
    await this.messagesFetch();
  },
  methods: {
    async messagesFetch(clear) {
      if (this.isLoading) return;
      if (clear) {
        this.currentPage = 1;
      }
      this.isLoading = true;

      const prevTotal = (this.currentPage - 1) * this.limit;
      try {
        const {
          data: messages,
          totalCount,
        } = await remoteMonitoringService.smsList({
          skip: prevTotal,
          take: this.limit,
          phoneNumber: this.phoneNumber,
        });
        this.totalCount = totalCount;
        this.messages = messages;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    tableSizeHandler(value) {
      this.limit = value;
      this.messagesFetch(true);
    },
    getFormatDate(date, formatter) {
      return date && formatter ? format(new Date(date), formatter) : null;
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.monitoring-create-call-header {
  font-weight: 500;
}
.modal-body {
  width: 100%;
  padding: 0 12px;
}
</style>
